import React from "react";
import {PagePoste} from 'amg-it-theme'; 

let pageData ={
  header: {
    background: "metiers",
    title: "accompagnement",
    path: "accueil / nos métiers / accompagnement / Consultant"
  },
	title: "Consultant", 
	missions:[
		{
			text: "Assister la maîtrise d’ouvrage dans l’expression du besoin" 
		},
		{
			text: "Conseiller dans le choix des solutions techniques ou métiers spécifiques" 
		},
		{
			text: "Développer les offres autour de son domaine d’expertise" 
		},
	],
  	key_skills: [
		{
		  text: "Communiquer (interne, hiérarchie, client, équipes)" 
		},
		{
		  text: "Contrôler, organiser et planifier" 
		},
		{
		  text: "Faire preuve d’écoute" 
		},
		{
		  text: "Analyser et comprendre" 
		},
		{
		  text: "Expression écrite" 
		},
		{
		  text: "Se positionner en leader" 
		},
		{
		  text: "Autonomie" 
		},
	],
	prerequisites:[
		{
		  text: "Expertise validée par évaluation" 
		},
		{
		  text: "Expérience de 3 ans minimum en tant que consultant confirmé" 
		},
		{
		  text: "Maitrise d’au moins une méthodologie de gestion de projet" 
		},
	],
	activities:[
		{
		  text: "Analyser l’existant, recueillir les besoins" 
		},
		{
		  text: "Réaliser des audits" 
		},
		{
		  text: "Mettre en œuvre des solutions" 
		},
		{
		  text: "Rédiger la documentation, les spécifications et livrables" 
		},
		{
		  text: "Conduire des projets" 
		},
		{
		  text: "Garantir la/les méthodologie(s) employée(s)" 
		},
		{
		  text: "Détecter les opportunités d’extension de prestation et de foisonnement commercial" 
		},
		{
		  text: "Assurer le transfert de compétences des consultants autour de son domaine d’expertise" 
		},
		{
		  text: "Participer aux phases d’avant-vente" 
		},
		{
		  text: "Participer au développement des offres" 
		}
	]

}
const LocalPage = () => (
  <PagePoste {...pageData}/>
);

export default LocalPage;